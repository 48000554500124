import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { settings } from './const';
import * as Styled from './styles';
import { Inner } from '../../styles/Grid/styles';
import Heading from '../../molecules/Heading';
import PowerConsumptionCard from '../../molecules/PowerConsumptionCard';
import { PowerConsumptionDataProps } from './types';
import { StaticQuery, graphql } from 'gatsby';


const PowerConsumption: React.FC<PowerConsumptionDataProps> = ({ data }) => {
  const { title, subtitle, description } = data;

  return (
    <Inner>
      <Heading
        {...{ title, subtitle }}
        content={
          <Styled.ReviewsSectionHead>
            <Styled.ReviewsSectionHeadDesc>{description}</Styled.ReviewsSectionHeadDesc>
          </Styled.ReviewsSectionHead>
        }
      />

      <Styled.ReviewsCarouselWrapper>
        

          <StaticQuery
            query={graphql`{
              powerCo2Emissions {
                lastYearCo2KgWithoutGOPerGuest
                lastYearCo2KgPhysicalPerGuest
                lastYearSevenDaysFromYesterdayCo2KgPhysicalPerGuest
                lastYearSevenDaysFromYesterdayCo2KgWithoutGoPerGuest
                thisYearLastThirtyDaysFromYesterdayCo2KgPhysicalPerGuest
                thisYearSevenDaysFromYesterdayCo2KgPhysicalPerGuest
                thisYearLastThirtyDaysFromYesterdayCo2KgWithoutGOPerGuest
                lastYearLastThirtyDaysFromYesterdayCo2KgPhysicalPerGuest
                lastYearLastThirtyDaysFromYesterdayCo2KgWithoutGoPerGuest
                thisYearSevenDaysFromYesterdayCo2KgWithoutGoPerGuest
                thisYearCo2KgPhysicalPerGuest
                thisYearCo2KgWithoutGOPerGuest
              }
            }
          `}
            render={data => (
              <Slider {...settings}>
                <PowerConsumptionCard
                  title={'This year to date'}
                  comment={'Co2 emissions per guest'}
                  thisTimeFrameKgCo2={data.powerCo2Emissions.thisYearCo2KgPhysicalPerGuest}
                  lastTimeFrameKgCo2={data.powerCo2Emissions.lastYearCo2KgPhysicalPerGuest}
                />
                <PowerConsumptionCard
                  title={'Last 30 days'}
                  comment={'Co2 emissions per guest'}
                  thisTimeFrameKgCo2={data.powerCo2Emissions.thisYearLastThirtyDaysFromYesterdayCo2KgPhysicalPerGuest}
                  lastTimeFrameKgCo2={data.powerCo2Emissions.lastYearLastThirtyDaysFromYesterdayCo2KgPhysicalPerGuest}
                />
                <PowerConsumptionCard
                  title={'Last 7 days'}
                  comment={'Co2 emissions per guest'}
                  thisTimeFrameKgCo2={data.powerCo2Emissions.thisYearSevenDaysFromYesterdayCo2KgPhysicalPerGuest}
                  lastTimeFrameKgCo2={data.powerCo2Emissions.lastYearSevenDaysFromYesterdayCo2KgPhysicalPerGuest}
                />
              </Slider>
              )
            }
          />

        
      </Styled.ReviewsCarouselWrapper>
    </Inner>
  );
};

export default PowerConsumption;
