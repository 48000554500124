import React from 'react';
import { Settings as CarouselSettings } from 'react-slick';

import * as Styled from '../../atoms/Carousel/styles';
import { IconArrowRightRound } from '../../../assets/svg';

export const settings: CarouselSettings = {
  dots: true,
  infinite: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
  nextArrow: (
    <Styled.CarouselNextArrow aria-label="Next Slide">
      <IconArrowRightRound />
    </Styled.CarouselNextArrow>
  ),
  prevArrow: (
    <Styled.CarouselPrevArrow aria-label="Prev Slide">
      <IconArrowRightRound />
    </Styled.CarouselPrevArrow>
  ),
  appendDots: (dots: React.ReactNode) => (
    <Styled.CarouselPaginationDots>{dots}</Styled.CarouselPaginationDots>
  ),
  customPaging: () => <Styled.CarouselPagination />,
};
