import React from 'react';

import { LinkMode } from '../../atoms/Link/enums';
import * as Styled from './styles';
import Button from '../../atoms/Button';
import { PowerConsumptionCardProps } from './types';

const PowerConsumptionCard: React.FC<PowerConsumptionCardProps> = ({
  icon,
  buttonLabel,
  thisTimeFrameKgCo2,
  lastTimeFrameKgCo2,
  title,
  maxCommentChars = 178,
  onReadMoreClick,
  isExpanded,
  comment
}) => {
  const hasLongComment: boolean = comment.length > maxCommentChars;
  return (
    <Styled.PowerConsumptionCard {...{ isExpanded }}>
      <Styled.PowerConsumptionCardHeading>
      <Styled.PowerConsumptionCardName variant="textL">
            {title}
          </Styled.PowerConsumptionCardName>
      </Styled.PowerConsumptionCardHeading>
      <Styled.PowerConsumptionCardContent>

        <Styled.PowerConsumptionCardThisCo2Emission>
          {thisTimeFrameKgCo2 + 'kg'}
        </Styled.PowerConsumptionCardThisCo2Emission>
        <p>vs {lastTimeFrameKgCo2} kg same period last year</p>
      </Styled.PowerConsumptionCardContent>
    </Styled.PowerConsumptionCard>
  );
};

export default PowerConsumptionCard;
