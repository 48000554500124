import Slider from 'react-slick';
import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import { Typography } from '../../styles/Typography/styles';

export const ReviewsCarouselWrapper = styled.div`
  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;
  }

  .slick-slide > div,
  .slick-slide > div > div {
    height: 100%;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    position: absolute;
    bottom: 0;
  }

  .slick-arrow.slick-prev {
    right: 5rem;
  }

  .slick-arrow.slick-next {
    right: 2.5rem;
  }

  .slick-dots {
    padding-top: 2rem;
    height: 4rem;
  }

  @media ${media.tablet} {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: calc(100% + 3rem);

    .slick-list {
      margin: 0;
    }

    .slick-slide > div {
      padding: 0;
    }
  }

  @media ${media.desktopSm} {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    width: calc(100% + 5rem);

    .slick-list {
      margin: 0 -1.875rem;
    }

    .slick-slide > div {
      padding: 0 1.875rem;
    }
  }
`;

export const ReviewsCarousel = styled(Slider)`
  position: relative;
`;

export const ReviewsSectionServiceRatings = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 4rem;

  @media${media.tablet} {
    margin-top: 4rem;
  }
`;

export const ReviewsCarouselItem = styled.div`
  &:focus {
    outline: 0;
  }
`;

export const ReviewsSectionHead = styled.div``;

export const ReviewsSectionHeadDesc = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
