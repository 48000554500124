import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import { FlexBox } from '../../styles/Grid/styles';
import { Typography } from '../../styles/Typography/styles';
import { transition } from '../../../common/mixins';
import { StyledPowerConsumptionCardProps } from './types';

export const PowerConsumptionCard = styled.div<StyledPowerConsumptionCardProps>`
  ${transition('border-color')}
  box-sizing: border-box;
  height: 100%;
  border-radius: 0.25rem;
  padding: ${({ isExpanded }) => (isExpanded ? '0' : '1.25rem')};
  border: 1px solid
    ${({ theme, isExpanded }) => (isExpanded ? 'transparent' : theme.colors.additional.accentGray1)};

  @media ${media.phone} {
    border-color: transparent;
    padding: ${({ isExpanded }) => (isExpanded ? '0' : '2.5rem')};
  }

  @media ${media.tablet} {
    &:hover {
      border-color: ${({ theme, isExpanded }) =>
        !isExpanded && theme.colors.additional.accentGray1};
    }
  }
`;

export const PowerConsumptionCardHeading = styled.div`
  display: flex;
`;

export const PowerConsumptionCardThisCo2Emission = styled.div`
  font-size: 4rem;
`;

export const PowerConsumptionCardAvatar = styled.div`
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
  border-radius: 50%;
  overflow: hidden;
`;

export const PowerConsumptionCardHeadingAside = styled.div`
  margin-left: 1.5rem;
`;

export const PowerConsumptionCardName = styled(Typography)`
  margin-top: 0.875rem;
`;

export const PowerConsumptionCardDate = styled(Typography)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const PowerConsumptionCardContent = styled(FlexBox)`
  flex-direction: column;
  height: calc(100% - 5rem);
`;

export const PowerConsumptionCardStartRate = styled(FlexBox)`
  margin-top: 1.5rem;
`;

export const PowerConsumptionCardComment = styled(Typography)`
  flex-grow: 1;
  line-height: 1.5;
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const PowerConsumptionCardAction = styled.div`
  margin-top: 0.75rem;
`;
