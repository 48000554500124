import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {  PowerConsumptionSectionProps } from './types';
import { Inner } from '../../../styles/Grid/styles';
import PowerConsumption from '../../PowerConsumption';


const ReviewsCarouselSection: React.VFC<PowerConsumptionSectionProps> = ({
  title,
  subtitle,
  description
}) => {

  return (
    <Inner>
      <PowerConsumption data={{
        title: title ?? '',
        subtitle: subtitle ?? '',
        description: description ?? ''}}/>
    </Inner>
  );
};

export const fragment = graphql`
  fragment PowerConsumptionSectionFragment on SanityPowerConsumptionSection {
    _key
    _type
    title
    subtitle
    description

    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default ReviewsCarouselSection;
